import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const EducationContainer = styled.section`
    padding: 4em 2em;
    background-color: #1e1e1e; /* Dark Background */
    color: #d3d3d3; /* Text Color */
    position: relative;
`;

const Title = styled(motion.h2)`
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 1em;
    color: #ffffff; /* White Text */
`;

const Cards = styled(motion.div)`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2em;
`;

const Card = styled(motion.div)`
    perspective: 1000px;
    width: 300px; /* Width adjustment */
    height: 350px; /* Height adjustment */
`;

const CardInner = styled(motion.div)`
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transform-style: preserve-3d;
    transition: transform 0.8s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:hover {
        transform: rotateY(180deg);
    }
`;

const CardFront = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #2c2c2c;
    color: #ffffff;
    border-radius: 8px;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.5em;
    padding: 1em;
`;

const CardBack = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #2c2c2c;
    color: #ffffff;
    border-radius: 8px;
    backface-visibility: hidden;
    transform: rotateY(180deg);
    padding: 2em;
    box-sizing: border-box;
`;

const CardHeader = styled.h3`
    font-size: 1.5em;
    color: #ffffff; /* White Text */
`;

const CardSubheader = styled.h4`
    font-size: 1.2em;
    color: #b3b3b3; /* Light Gray Text */
    margin-bottom: 1em;
`;

const CardDescription = styled.p`
    font-size: 1em;
    line-height: 1.6;
    color: #d3d3d3; /* Text Color */
`;

const Icon = styled.i`
    font-size: 3em;
    margin-bottom: 0.5em;
`;

const BackgroundGraphic = styled(motion.div)`
    position: absolute;
    width: 400px;
    height: 400px;
    background: radial-gradient(circle, rgba(63,81,181,0.5) 0%, rgba(63,81,181,0) 70%);
    border-radius: 50%;
    top: -100px;
    left: -100px;
    z-index: 0;
`;

const BackgroundGraphic1 = styled(motion.div)`
    position: absolute;
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, rgba(245,0,87,0.5) 0%, rgba(245,0,87,0) 70%);
    border-radius: 50%;
    bottom: -150px;
    right: -150px;
    z-index: 0;
`;

const BackgroundGraphic2 = styled(motion.div)`
    position: absolute;
    width: 200px;
    height: 200px;
    background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
    border-radius: 50%;
    top: 200px;
    right: 200px;
    z-index: 0;
`;

const Education = () => (
    <EducationContainer id="education">
        <BackgroundGraphic
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.7 }}
            transition={{ duration: 2 }}
        />
        <BackgroundGraphic1
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            transition={{ duration: 2, delay: 1 }}
        />
        <BackgroundGraphic2
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.3 }}
            transition={{ duration: 2, delay: 1.5 }}
        />
        <Title
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
        >
            Education
        </Title>
        <Cards
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
        >
            <Card>
                <CardInner>
                    <CardFront>
                        <Icon className="fas fa-university"></Icon>
                        <span>Master of Science in CS</span>
                    </CardFront>
                    <CardBack>
                        <CardHeader>Master of Science in CS (On-going)</CardHeader>
                        <CardSubheader>2023 – Present</CardSubheader>
                        <CardDescription>
                            University of Central Missouri - GPA: 3.66
                        </CardDescription>
                    </CardBack>
                </CardInner>
            </Card>
            <Card>
                <CardInner>
                    <CardFront>
                        <Icon className="fas fa-graduation-cap"></Icon>
                        <span>B.Tech in Electronics</span>
                    </CardFront>
                    <CardBack>
                        <CardHeader>B.Tech in Electronics</CardHeader>
                        <CardSubheader>2019 – 2023</CardSubheader>
                        <CardDescription>
                            Vel Tech University - GPA: 9.1
                        </CardDescription>
                    </CardBack>
                </CardInner>
            </Card>
            <Card>
                <CardInner>
                    <CardFront>
                        <Icon className="fas fa-school"></Icon>
                        <span>Intermediate</span>
                    </CardFront>
                    <CardBack>
                        <CardHeader>Intermediate (Science Stream)</CardHeader>
                        <CardSubheader>2017 – 2019</CardSubheader>
                        <CardDescription>
                            Sri Chaitanya Junior College, Amalapuram - GPA: 9.94
                        </CardDescription>
                    </CardBack>
                </CardInner>
            </Card>
            <Card>
                <CardInner>
                    <CardFront>
                        <Icon className="fas fa-book"></Icon>
                        <span>10th Class</span>
                    </CardFront>
                    <CardBack>
                        <CardHeader>10th Class</CardHeader>
                        <CardSubheader>2016 – 2017</CardSubheader>
                        <CardDescription>
                            Jawahar Navodaya Vidyalaya, Yanam - GPA: 10
                        </CardDescription>
                    </CardBack>
                </CardInner>
            </Card>
        </Cards>
    </EducationContainer>
);

export default Education;
