import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaFilePdf } from 'react-icons/fa';

const ResumeContainer = styled.section`
    padding: 4em 2em;
    background-color: #1e1e1e; /* Dark Background */
    color: #d3d3d3; /* Text Color */
    position: relative;
    overflow: hidden;
    text-align: center;
`;

const Title = styled.h2`
    font-size: 2.5em;
    margin-bottom: 1em;
    color: #ffffff; /* White Text */
`;

const Description = styled.p`
    font-size: 1.2em;
    margin-bottom: 2em;
    color: #b3b3b3; /* Light Gray Text */
`;

const DownloadButton = styled.a`
    display: inline-flex;
    align-items: center;
    background-color: #f50057; /* Accent Color */
    color: #ffffff;
    padding: 0.75em 1.5em;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1.2em;
    font-weight: 600;
    transition: background-color 0.3s;
    margin-top: 1em;
    &:hover {
        background-color: #d4004d;
    }
    svg {
        margin-left: 0.5em;
    }
`;

const IframeContainer = styled.div`
    width: 100%;
    height: 600px;
    margin-top: 2em;
    border: 1px solid #2c2c2c;
`;

const Iframe = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
`;

const BackgroundGraphic = styled(motion.div)`
    position: absolute;
    width: 400px;
    height: 400px;
    background: radial-gradient(circle, rgba(63,81,181,0.5) 0%, rgba(63,81,181,0) 70%);
    border-radius: 50%;
    top: -100px;
    left: -100px;
    z-index: 0;
`;

const BackgroundGraphic1 = styled(motion.div)`
    position: absolute;
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, rgba(245,0,87,0.5) 0%, rgba(245,0,87,0) 70%);
    border-radius: 50%;
    bottom: -150px;
    right: -150px;
    z-index: 0;
`;

const BackgroundGraphic2 = styled(motion.div)`
    position: absolute;
    width: 200px;
    height: 200px;
    background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
    border-radius: 50%;
    top: 200px;
    right: 200px;
    z-index: 0;
`;

const Resume = () => (
    <ResumeContainer id="resume">
        <BackgroundGraphic
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.7 }}
            transition={{ duration: 2 }}
        />
        <BackgroundGraphic1
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            transition={{ duration: 2, delay: 1 }}
        />
        <BackgroundGraphic2
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.3 }}
            transition={{ duration: 2, delay: 1.5 }}
        />
        <Title>Resume</Title>
        <Description>
            Click the button below to download my resume or view it directly in the frame below.
        </Description>
        <DownloadButton href="https://drive.google.com/file/d/1wxoIZUkzGvIB0pUalK9iMYaku8asfKWP/view?usp=sharing" target="_blank" rel="noopener noreferrer">
            Download Resume <FaFilePdf />
        </DownloadButton>
        <IframeContainer>
            <Iframe src="https://drive.google.com/file/d/1wxoIZUkzGvIB0pUalK9iMYaku8asfKWP/preview" />
        </IframeContainer>
    </ResumeContainer>
);

export default Resume;
