import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const HeroContainer = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4em 2em;
    background-color: #121212; /* Background Color (Metal Black) */
    color: #d3d3d3; /* Text Color */
    position: relative; /* For absolute positioning of graphics */
    overflow: hidden;
`;

const ContentContainer = styled.div`
    flex: 1;
    max-width: 600px;
`;

const ProfileCard = styled.div`
    flex: 1;
    max-width: 400px;
    width: 100%;
    height: auto;
    margin-left: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ProfileImage = styled(motion.img)`
    width: 100%;
    height: auto;
    border-radius: 10px;
`;

const SocialLinks = styled.div`
    display: flex;
    gap: 1em;
    margin-top: 1em;
`;

const SocialLink = styled.a`
    color: #ffffff; /* Accent Color */
    font-size: 1.5em;
`;

const BackgroundGraphic = styled(motion.div)`
    position: absolute;
    width: 400px;
    height: 400px;
    background: radial-gradient(circle, rgba(63,81,181,0.5) 0%, rgba(63,81,181,0) 70%);
    border-radius: 50%;
    top: -100px;
    left: -100px;
    z-index: 0;
`;

const BackgroundGraphic1 = styled(motion.div)`
    position: absolute;
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, rgba(245,0,87,0.5) 0%, rgba(245,0,87,0) 70%);
    border-radius: 50%;
    bottom: -150px;
    right: -150px;
    z-index: 0;
`;

const BackgroundGraphic2 = styled(motion.div)`
    position: absolute;
    width: 200px;
    height: 200px;
    background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
    border-radius: 50%;
    top: 200px;
    right: 200px;
    z-index: 0;
`;

const Hero = () => (
    <HeroContainer id="about">
        <BackgroundGraphic
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.7 }}
            transition={{ duration: 2 }}
        />
        <BackgroundGraphic1
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            transition={{ duration: 2, delay: 1 }}
        />
        <BackgroundGraphic2
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.3 }}
            transition={{ duration: 2, delay: 1.5 }}
        />
        <ContentContainer>
            <motion.h2
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
            >
                Issac Kondreddy
            </motion.h2>
            <motion.h3
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 0.2 }}
            >
                A Masters Student in Computer Science
            </motion.h3>
            <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 1 }}
            >
                Hello! I'm Issac Kondreddy, a passionate developer specializing in Machine Learning, AI, and Python Development. My journey in tech began at University of Central Missouri where I developed a deep interest in Artificial Intelligence.
            </motion.p>
            <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.7, duration: 1 }}
            >
                Over the years, I've honed my skills through various projects and challenges, focusing on Machine Learning, Deep Learning, Quantum Machine learning, Computational Intelligence. I'm always eager to learn new technologies and apply them to solve real-world problems.
            </motion.p>
            <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.9, duration: 1 }}
            >
                When I'm not coding, you can find me exploring new places, capturing moments through my camera, and indulging in Travelling, Chess, Badminton.
            </motion.p>
            <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1.1, duration: 1 }}
            >
                I'm excited to showcase my journey and the projects I've worked on. Feel free to scroll through my portfolio to learn more about my expertise and experiences!
            </motion.p>
            <SocialLinks>
                <SocialLink href="https://www.instagram.com/issac" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"></i>
                </SocialLink>
                <SocialLink href="https://www.linkedin.com/in/issac" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-linkedin"></i>
                </SocialLink>
                <SocialLink href="https://github.com/issac" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-github"></i>
                </SocialLink>
                <SocialLink href="mailto:issac@example.com">
                    <i className="fas fa-envelope"></i>
                </SocialLink>
            </SocialLinks>
        </ContentContainer>
        <ProfileCard>
            <ProfileImage 
                src="/images/alternativepic.jpeg" 
                alt="Issac Kondreddy" 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 2 }}
            />
        </ProfileCard>
    </HeroContainer>
);

export default Hero;
