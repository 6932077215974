import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import styled from 'styled-components';

const HeaderContainer = styled.header`
    background-color: #2c2c2c; /* Primary Color (Gray) */
    padding: 1em 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    z-index: 1000;
`;

const Nav = styled.nav`
    display: flex;
    gap: 2em;
`;

const NavLink = styled(ScrollLink)`
    color: #ffffff; /* Accent Color */
    text-decoration: none;
    font-family: 'Arial', sans-serif; /* Font */
    font-size: 1.1em;
    font-weight: 500;
    position: relative;
    transition: color 0.3s;
    cursor: pointer;

    &:hover {
        color: #f50057; /* Highlight Color */
    }
    
    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #f50057; /* Highlight Color */
        transition: width 0.3s;
    }
    
    &:hover::after {
        width: 100%;
    }
`;

const Header = () => (
    <HeaderContainer>
        <Nav>
            <NavLink to="about" smooth={true} duration={500}>About</NavLink>
            <NavLink to="education" smooth={true} duration={500}>Education</NavLink>
            <NavLink to="skills" smooth={true} duration={500}>Skills</NavLink>
            <NavLink to="projects" smooth={true} duration={500}>Projects</NavLink>
            <NavLink to="publications" smooth={true} duration={500}>Publications</NavLink>
            <NavLink to="experience" smooth={true} duration={500}>Experience</NavLink>
            <NavLink to="contact" smooth={true} duration={500}>Contact</NavLink>
            <NavLink to="resume" smooth={true} duration={500}>Download Resume</NavLink>
        </Nav>
    </HeaderContainer>
);

export default Header;
