import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaBook, FaLink } from 'react-icons/fa';

const PublicationsContainer = styled.section`
    padding: 4em 2em;
    background-color: #1e1e1e; /* Dark Background */
    color: #d3d3d3; /* Text Color */
    position: relative;
    overflow: hidden;
`;

const Title = styled.h2`
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 1em;
    color: #ffffff; /* White Text */
`;

const PublicationsList = styled.ul`
    list-style: none;
    padding: 0;
`;

const PublicationItem = styled(motion.li)`
    background-color: #2c2c2c;
    padding: 1.5em;
    border-radius: 10px;
    margin-bottom: 1em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    &:hover {
        transform: translateY(-10px);
    }
`;

const PublicationTitle = styled.h3`
    font-size: 1.2em;
    color: #ffffff;
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
`;

const PublicationDetails = styled.p`
    font-size: 1em;
    line-height: 1.6;
    color: #b3b3b3;
    margin-bottom: 0.5em;
`;

const PublicationLink = styled.a`
    color: #f50057; /* Highlight Color */
    text-decoration: none;
    display: flex;
    align-items: center;
    &:hover {
        text-decoration: underline;
    }
`;

const BackgroundGraphic = styled(motion.div)`
    position: absolute;
    width: 400px;
    height: 400px;
    background: radial-gradient(circle, rgba(63,81,181,0.5) 0%, rgba(63,81,181,0) 70%);
    border-radius: 50%;
    top: -100px;
    left: -100px;
    z-index: 0;
`;

const BackgroundGraphic1 = styled(motion.div)`
    position: absolute;
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, rgba(245,0,87,0.5) 0%, rgba(245,0,87,0) 70%);
    border-radius: 50%;
    bottom: -150px;
    right: -150px;
    z-index: 0;
`;

const BackgroundGraphic2 = styled(motion.div)`
    position: absolute;
    width: 200px;
    height: 200px;
    background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
    border-radius: 50%;
    top: 200px;
    right: 200px;
    z-index: 0;
`;

const IconContainer = styled.div`
    margin-right: 0.5em;
`;

const publicationsData = [
    {
        title: "Investigation on Enhancing the Binary Classification Accuracy of Supervised Classifiers Using Various Transform",
        journal: "Journal of Applied Science",
        details: "vol. 1084, no. 1, 2021, pp. 012032",
        link: "https://iopscience.iop.org/article/10.1088/1757-899X/1084/1/012032",
    },
    {
        title: "Feature Selection Using Elephant Herding Optimization Hybridized with GreyWolf Optimization for Anomaly Detection in Wafer Manufacturing",
        journal: "Proceedings of the International Conference on Advanced Computational and Communication Paradigms, 2021",
        link: "https://www.researchgate.net/publication/356838328_Feature_Selection_Using_Elephant_Herding_Optimization_Hybridized_with_Gray_Wolf_Optimization_for_Anomaly_Detection_in_Wafer_Manufacturing",
    },
    {
        title: "A Study on Real World Implementation and Future Trends of Internet of Things",
        journal: "IEEE Xplore, 2021",
        link: "https://ieeexplore.ieee.org/document/9243551",
    },
    {
        title: "Improving the Performance Metrics of Binary Classification Models with Different Transforms",
        journal: "International Research Journal of Engineering and Technology, vol. 8, no. 6, 2021",
        link: "https://www.irjet.net/archives/V8/i6/IRJET-V8I6112.pdf",
    },
    {
        title: "Anomaly Detection Using Supervised Classifiers Combined with Feature Clustering Techniques",
        journal: "International Conference on Neural and Advanced Technologies, 2021",
        //link: "https://link.springer.com/chapter/10.1007/978-3-030-75943-7_21",
    },
    {
        title: "Quanvolution Neural Network to Recognize Arrhythmia from 2D Scaleogram Features of ECG Signals",
        journal: "IEEE Xplore, 2022",
        link: "https://ieeexplore.ieee.org/document/9744224",
    },
    {
        title: "A Review: The Success of Tesla from 2003 to 2022",
        journal: "International Research Journal of Engineering and Technology, vol. 9, no. 4, 2022",
        link: "https://www.irjet.net/archives/V9/i4/IRJET-V9I4345.pdf",
    },
    {
        title: "Leveraging Quantum Feature Extraction and Transfer Learning for Alzheimer’s Disease Diagnosis and Classification",
        journal: "Accepted at International Conference on the Science of Science and Technological Advances, 2023",
        //link: "https://link.springer.com/chapter/10.1007/978-3-030-77004-3_34",
    },
];

const Publications = () => (
    <PublicationsContainer id="publications">
        <BackgroundGraphic
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.7 }}
            transition={{ duration: 2 }}
        />
        <BackgroundGraphic1
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            transition={{ duration: 2, delay: 1 }}
        />
        <BackgroundGraphic2
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.3 }}
            transition={{ duration: 2, delay: 1.5 }}
        />
        <Title>Publications</Title>
        <PublicationsList>
            {publicationsData.map((publication, index) => (
                <PublicationItem
                    key={index}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                    <PublicationTitle>
                        <IconContainer>
                            <FaBook />
                        </IconContainer>
                        {publication.title}
                    </PublicationTitle>
                    <PublicationDetails>{publication.journal}</PublicationDetails>
                    <PublicationDetails>{publication.details}</PublicationDetails>
                    <PublicationLink href={publication.link} target="_blank" rel="noopener noreferrer">
                        View Publication <FaLink />
                    </PublicationLink>
                </PublicationItem>
            ))}
        </PublicationsList>
    </PublicationsContainer>
);

export default Publications;
