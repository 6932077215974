import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaTrafficLight, FaMicroscope, FaBrain, FaHeartbeat, FaGithub, FaSearch } from 'react-icons/fa';

const ProjectsContainer = styled.section`
    padding: 4em 2em;
    background-color: #1e1e1e; /* Dark Background */
    color: #d3d3d3; /* Text Color */
    position: relative;
    overflow: hidden;
`;

const Title = styled.h2`
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 1em;
    color: #ffffff; /* White Text */
`;

const ProjectsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2em;
`;

const ProjectCard = styled(motion.div)`
    background-color: #2c2c2c;
    padding: 1.5em;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    &:hover {
        transform: translateY(-10px);
    }
`;

const ProjectThumbnail = styled.div`
    font-size: 3em;
    color: #f50057; /* Highlight Color */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
`;

const ProjectTitle = styled.h3`
    font-size: 1.5em;
    color: #ffffff;
    margin-bottom: 0.5em;
`;

const ProjectDescription = styled.p`
    font-size: 1em;
    line-height: 1.6;
    margin-bottom: 1em;
`;

const Technologies = styled.div`
    font-size: 0.9em;
    color: #b3b3b3;
`;

const ProjectLink = styled.a`
    color: #ffffff; /* Accent Color */
    display: flex;
    align-items: center;
    margin-top: 0.5em;
    &:hover {
        color: #f50057; /* Highlight Color */
    }
    svg {
        margin-left: 0.5em;
    }
`;

const BackgroundGraphic = styled(motion.div)`
    position: absolute;
    width: 400px;
    height: 400px;
    background: radial-gradient(circle, rgba(63,81,181,0.5) 0%, rgba(63,81,181,0) 70%);
    border-radius: 50%;
    top: -100px;
    left: -100px;
    z-index: 0;
`;

const BackgroundGraphic1 = styled(motion.div)`
    position: absolute;
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, rgba(245,0,87,0.5) 0%, rgba(245,0,87,0) 70%);
    border-radius: 50%;
    bottom: -150px;
    right: -150px;
    z-index: 0;
`;

const BackgroundGraphic2 = styled(motion.div)`
    position: absolute;
    width: 200px;
    height: 200px;
    background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
    border-radius: 50%;
    top: 200px;
    right: 200px;
    z-index: 0;
`;

const projectsData = [
    {
        title: 'Traffic Signal Detection with YOLO Algorithm',
        description: 'Designed a YOLO-based system for real-time traffic signal detection, trained on 5,000+ images, reducing congestion significantly. Achieved 80% prediction accuracy using Python with OpenCV and TensorFlow in neural network implementation.',
        technologies: 'Machine Learning, Deep Learning, Python, OpenCV, TensorFlow',
        icon: <FaTrafficLight />,
    },
    {
        title: 'Breast Cancer Classification via Metaheuristic Algorithms',
        description: 'Formulated a classification system using metaheuristic algorithms to boost early breast cancer detection accuracy by using 20000 samples. Crafted and refined predictive models using Python, streamlined data manipulation with advanced TensorFlow’s neural network algorithms for robust training protocols.',
        technologies: 'Machine Learning, Scikit-learn, TensorFlow, Computational Intelligence, MATLAB',
        icon: <FaMicroscope />,
    },
    {
        title: 'Quantum Learning for Alzheimer’s Disease Classification',
        description: 'Conducted quantum machine learning research on Alzheimer’s classification, a novel diagnostic method. Analyzed over 10,000 medical images, demonstrating improved classification precision. Published research findings in a reputable journal, cited by peers 30+ times, contributing significantly to advancements in quantum computing and neurological disease understanding.',
        technologies: 'Quantum Machine Learning, Qiskit, TensorFlow Quantum',
        icon: <FaBrain />,
    },
    {
        title: 'Hybrid Deep Learning & Quantum Model for ECG Classification',
        description: 'Developed a hybrid deep learning and quantum computing model for advanced ECG signal classification and arrhythmia detection with 50,000 samples in CSV format. Achieved a 95% accuracy rate in cardiac arrhythmia diagnosis from ECG data, demonstrating the model’s effectiveness.',
        technologies: 'Deep Learning, Quantum Computing, TensorFlow, Qiskit',
        icon: <FaHeartbeat />,
    },
    {
        title: 'ML-Classification-Analysis',
        description: 'The project focuses on applying various machine learning classification models to the Ames Housing dataset. This encompasses data preprocessing, EDA, model selection, hyperparameter tuning, and evaluation to predict house prices.',
        technologies: 'Python, Pandas, NumPy, Scikit-Learn, Matplotlib, Seaborn',
        icon: <FaGithub />,
        link: 'https://github.com/Issac-Kondreddy/ML-Classification-Analysis',
    },
    {
        title: 'Metaheuristic algorithms + Deep Learning for Alzheimer’s Prediction',
        description: 'Utilized metaheuristic algorithms and deep learning techniques to predict Alzheimer’s disease. The project aims to improve the prediction accuracy by leveraging advanced optimization and neural network models.',
        technologies: 'Metaheuristic Algorithms, Deep Learning, Python',
        icon: <FaSearch />,
        link: 'https://github.com/Issac-Kondreddy/Alzheimers_Prediction/tree/master',
    },
];

const Projects = () => (
    <ProjectsContainer id="projects">
        <BackgroundGraphic
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.7 }}
            transition={{ duration: 2 }}
        />
        <BackgroundGraphic1
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            transition={{ duration: 2, delay: 1 }}
        />
        <BackgroundGraphic2
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.3 }}
            transition={{ duration: 2, delay: 1.5 }}
        />
        <Title>Projects</Title>
        <ProjectsGrid>
            {projectsData.map((project, index) => (
                <ProjectCard
                    key={index}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                    <ProjectThumbnail>{project.icon}</ProjectThumbnail>
                    <ProjectTitle>{project.title}</ProjectTitle>
                    <ProjectDescription>{project.description}</ProjectDescription>
                    <Technologies>{project.technologies}</Technologies>
                    {project.link && (
                        <ProjectLink href={project.link} target="_blank" rel="noopener noreferrer">
                            View on GitHub <FaGithub />
                        </ProjectLink>
                    )}
                </ProjectCard>
            ))}
        </ProjectsGrid>
    </ProjectsContainer>
);

export default Projects;
