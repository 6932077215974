import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaUniversity, FaProjectDiagram, FaAward } from 'react-icons/fa';

const ExperienceContainer = styled.section`
    padding: 4em 2em;
    background-color: #1e1e1e; /* Dark Background */
    color: #d3d3d3; /* Text Color */
    position: relative;
    overflow: hidden;
`;

const Title = styled.h2`
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 1em;
    color: #ffffff; /* White Text */
`;

const ExperienceList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2em;
    max-width: 800px;
    margin: 0 auto;
`;

const ExperienceItem = styled.div`
    background-color: #2c2c2c;
    padding: 1.5em;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 1em;
`;

const IconContainer = styled.div`
    font-size: 3em;
    color: #f50057; /* Accent Color */
`;

const ExperienceDetails = styled.div`
    text-align: left;
`;

const ExperienceTitle = styled.h3`
    font-size: 1.5em;
    color: #ffffff; /* White Text */
`;

const ExperienceSubTitle = styled.h4`
    font-size: 1.2em;
    color: #b3b3b3; /* Light Gray Text */
    margin-bottom: 0.5em;
`;

const ExperienceDescription = styled.p`
    font-size: 1em;
    line-height: 1.6;
`;

const BackgroundGraphic = styled(motion.div)`
    position: absolute;
    width: 400px;
    height: 400px;
    background: radial-gradient(circle, rgba(63,81,181,0.5) 0%, rgba(63,81,181,0) 70%);
    border-radius: 50%;
    top: -100px;
    left: -100px;
    z-index: 0;
`;

const BackgroundGraphic1 = styled(motion.div)`
    position: absolute;
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, rgba(245,0,87,0.5) 0%, rgba(245,0,87,0) 70%);
    border-radius: 50%;
    bottom: -150px;
    right: -150px;
    z-index: 0;
`;

const BackgroundGraphic2 = styled(motion.div)`
    position: absolute;
    width: 200px;
    height: 200px;
    background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
    border-radius: 50%;
    top: 200px;
    right: 200px;
    z-index: 0;
`;

const Experience = () => (
    <ExperienceContainer id="experience">
        <BackgroundGraphic
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.7 }}
            transition={{ duration: 2 }}
        />
        <BackgroundGraphic1
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            transition={{ duration: 2, delay: 1 }}
        />
        <BackgroundGraphic2
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.3 }}
            transition={{ duration: 2, delay: 1.5 }}
        />
        <Title>Professional Experience</Title>
        <ExperienceList>
            <ExperienceItem>
                <IconContainer>
                    <FaProjectDiagram />
                </IconContainer>
                <ExperienceDetails>
                    <ExperienceTitle>Research Intern</ExperienceTitle>
                    <ExperienceSubTitle>Thompson Rivers University, Kamloops, BC, Canada</ExperienceSubTitle>
                    <ExperienceSubTitle>July 2022 – October 2022</ExperienceSubTitle>
                    <ExperienceDescription>
                        Project: Proposed a Metaheuristic Algorithm for Breast Cancer Detection using machine learning techniques, achieving an accuracy rate of 95% in diagnosing early-stage cancers.
                        <ul>
                            <li>Analyzed 20 Metaheuristic Algorithms for BEC Optimization, conducting in-depth statistical validation.</li>
                            <li>Selected and implemented a Quantum Machine Learning Algorithm, boosting classification accuracy to 95%.</li>
                        </ul>
                    </ExperienceDescription>
                </ExperienceDetails>
            </ExperienceItem>
        </ExperienceList>
    </ExperienceContainer>
);

export default Experience;
