import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaPython, FaJava, FaDatabase, FaGitAlt, FaRobot } from 'react-icons/fa';
import { SiC, SiR, SiCplusplus, SiTensorflow, SiDjango, SiPandas } from 'react-icons/si';

const SkillsContainer = styled.section`
    padding: 4em 2em;
    background-color: #1e1e1e; /* Dark Background */
    color: #d3d3d3; /* Text Color */
    position: relative;
    overflow: hidden;
`;

const Title = styled.h2`
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 1em;
    color: #ffffff; /* White Text */
`;

const SkillsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2em;
`;

const SkillCategory = styled(motion.div)`
    background-color: #2c2c2c;
    padding: 1.5em;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    &:hover {
        transform: translateY(-10px);
    }
`;

const SkillTitle = styled.h3`
    font-size: 1.5em;
    color: #ffffff;
    margin-bottom: 0.5em;
`;

const ProgressBarContainer = styled.div`
    background-color: #3a3a3a;
    border-radius: 5px;
    overflow: hidden;
    height: 8px; /* Adjusted thickness */
    margin-bottom: 1em;
    position: relative;
`;

const ProgressBar = styled(motion.div)`
    height: 100%;
    background-color: #f50057; /* Highlight Color */
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 5px;
`;

const SkillList = styled.div`
    margin-top: 1em;
`;

const SkillItem = styled.div`
    margin-bottom: 0.5em;
`;

const BackgroundGraphic = styled(motion.div)`
    position: absolute;
    width: 400px;
    height: 400px;
    background: radial-gradient(circle, rgba(63,81,181,0.5) 0%, rgba(63,81,181,0) 70%);
    border-radius: 50%;
    top: -100px;
    left: -100px;
    z-index: 0;
`;

const BackgroundGraphic1 = styled(motion.div)`
    position: absolute;
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, rgba(245,0,87,0.5) 0%, rgba(245,0,87,0) 70%);
    border-radius: 50%;
    bottom: -150px;
    right: -150px;
    z-index: 0;
`;

const BackgroundGraphic2 = styled(motion.div)`
    position: absolute;
    width: 200px;
    height: 200px;
    background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
    border-radius: 50%;
    top: 200px;
    right: 200px;
    z-index: 0;
`;

const skillsData = [
    {
        category: 'Programming Languages',
        skills: [
            { name: 'Python', level: 90, icon: <FaPython /> },
            { name: 'Java', level: 85, icon: <FaJava /> },
            { name: 'R', level: 60, icon: <SiR /> },
            { name: 'C', level: 80, icon: <SiC /> },
            { name: 'C++', level: 60, icon: <SiCplusplus /> },
        ],
    },
    {
        category: 'Machine Learning AI',
        skills: [
            { name: 'Machine Learning', level: 85, icon: <FaRobot /> },
            { name: 'Deep Learning', level: 80, icon: <FaRobot /> },
            { name: 'Quantum Machine Learning', level: 60, icon: <FaRobot /> },
        ],
    },
    {
        category: 'Data Science',
        skills: [
            { name: 'Data Analysis', level: 80, icon: <SiPandas /> },
            { name: 'Image Processing', level: 75, icon: <SiPandas /> },
        ],
    },
    {
        category: 'Software Engineering',
        skills: [
            { name: 'Data Structures', level: 70, icon: <FaDatabase /> },
            { name: 'Algorithms', level: 70, icon: <FaDatabase /> },
            { name: 'OOPS', level: 85, icon: <FaDatabase /> },
        ],
    },
    {
        category: 'Database Management',
        skills: [
            { name: 'SQL', level: 80, icon: <FaDatabase /> },
        ],
    },
    {
        category: 'Libraries/Frameworks',
        skills: [
            { name: 'Scikit-Learn', level: 80, icon: <FaDatabase /> },
            { name: 'NumPy', level: 85, icon: <FaDatabase /> },
            { name: 'Matplotlib', level: 75, icon: <FaDatabase /> },
            { name: 'Pandas', level: 80, icon: <FaDatabase /> },
            { name: 'Seaborn', level: 70, icon: <FaDatabase /> },
            { name: 'Keras', level: 75, icon: <FaDatabase /> },
            { name: 'TensorFlow', level: 70, icon: <SiTensorflow /> },
            { name: 'Qiskit', level: 60, icon: <FaDatabase /> },
            { name: 'PennyLane', level: 60, icon: <FaDatabase /> },
            { name: 'Django', level: 60, icon: <SiDjango /> },
        ],
    },
    {
        category: 'Tools and Platforms',
        skills: [
            { name: 'Git/Github', level: 85, icon: <FaGitAlt /> },
        ],
    },
];

const Skills = () => (
    <SkillsContainer id="skills">
        <BackgroundGraphic
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.7 }}
            transition={{ duration: 2 }}
        />
        <BackgroundGraphic1
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            transition={{ duration: 2, delay: 1 }}
        />
        <BackgroundGraphic2
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.3 }}
            transition={{ duration: 2, delay: 1.5 }}
        />
        <Title>Skills and Activities</Title>
        <SkillsGrid>
            {skillsData.map((category, index) => (
                <SkillCategory
                    key={index}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                    <SkillTitle>{category.category}</SkillTitle>
                    <SkillList>
                        {category.skills.map((skill, idx) => (
                            <SkillItem key={idx}>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5em' }}>
                                    {skill.icon}
                                    <span style={{ marginLeft: '0.5em', flex: 1 }}>{skill.name}</span>
                                </div>
                                <ProgressBarContainer>
                                    <ProgressBar
                                        initial={{ width: 0 }}
                                        animate={{ width: `${skill.level}%` }}
                                        transition={{ duration: 1 }}
                                    />
                                </ProgressBarContainer>
                            </SkillItem>
                        ))}
                    </SkillList>
                </SkillCategory>
            ))}
        </SkillsGrid>
    </SkillsContainer>
);

export default Skills;
