import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ContactContainer = styled.section`
    padding: 4em 2em;
    background-color: #1e1e1e; /* Dark Background */
    color: #d3d3d3; /* Text Color */
    position: relative;
    overflow: hidden;
    text-align: center;
`;

const Title = styled.h2`
    font-size: 2.5em;
    margin-bottom: 1em;
    color: #ffffff; /* White Text */
`;

const Description = styled.p`
    font-size: 1.2em;
    margin-bottom: 2em;
    color: #b3b3b3; /* Light Gray Text */
`;

const Form = styled.form`
    max-width: 600px;
    margin: 0 auto;
`;

const Input = styled.input`
    width: 100%;
    padding: 0.75em;
    margin: 0.5em 0;
    border: none;
    border-radius: 5px;
    background-color: #2c2c2c;
    color: #ffffff;
`;

const Textarea = styled.textarea`
    width: 100%;
    padding: 0.75em;
    margin: 0.5em 0;
    border: none;
    border-radius: 5px;
    background-color: #2c2c2c;
    color: #ffffff;
`;

const SubmitButton = styled.button`
    background-color: #f50057; /* Accent Color */
    color: #ffffff;
    padding: 0.75em 1.5em;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 1em;
    &:hover {
        background-color: #d4004d;
    }
`;

const BackgroundGraphic = styled(motion.div)`
    position: absolute;
    width: 400px;
    height: 400px;
    background: radial-gradient(circle, rgba(63,81,181,0.5) 0%, rgba(63,81,181,0) 70%);
    border-radius: 50%;
    top: -100px;
    left: -100px;
    z-index: 0;
`;

const BackgroundGraphic1 = styled(motion.div)`
    position: absolute;
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, rgba(245,0,87,0.5) 0%, rgba(245,0,87,0) 70%);
    border-radius: 50%;
    bottom: -150px;
    right: -150px;
    z-index: 0;
`;

const BackgroundGraphic2 = styled(motion.div)`
    position: absolute;
    width: 200px;
    height: 200px;
    background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
    border-radius: 50%;
    top: 200px;
    right: 200px;
    z-index: 0;
`;

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Send the form data to Formspree
        fetch('https://formspree.io/f/xyzggnld', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(response => {
            if (response.ok) {
                alert('Form submitted successfully!');
                setFormData({ name: '', email: '', message: '' }); // Reset form data
            } else {
                alert('Form submission failed. Please try again.');
            }
        })
        .catch(error => {
            console.error('Form submission error:', error);
            alert('Form submission failed. Please try again.');
        });
    };

    return (
        <ContactContainer id="contact">
            <BackgroundGraphic
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.7 }}
                transition={{ duration: 2 }}
            />
            <BackgroundGraphic1
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.5 }}
                transition={{ duration: 2, delay: 1 }}
            />
            <BackgroundGraphic2
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.3 }}
                transition={{ duration: 2, delay: 1.5 }}
            />
            <Title>Contact Me</Title>
            <Description>Feel free to reach out for any inquiries or just to say hello!</Description>
            <Form onSubmit={handleSubmit}>
                <Input 
                    type="text" 
                    name="name" 
                    placeholder="Your Name" 
                    value={formData.name} 
                    onChange={handleChange} 
                    required 
                />
                <Input 
                    type="email" 
                    name="email" 
                    placeholder="Your Email" 
                    value={formData.email} 
                    onChange={handleChange} 
                    required 
                />
                <Textarea 
                    name="message" 
                    rows="5" 
                    placeholder="Your Message" 
                    value={formData.message} 
                    onChange={handleChange} 
                    required 
                />
                <SubmitButton type="submit">Send Message</SubmitButton>
            </Form>
        </ContactContainer>
    );
};

export default Contact;
