import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Education from './components/Education';
import Projects from './components/Projects';
import Publications from './components/Publications';
import Resume from './components/Resume';
import Skills from './components/Skills';
import Contact from './components/Contact';
import Experience from './components/Experience';
import Footer from './components/Footer';
import './App.css';

const App = () => (
    <Router>
        <div id="root">
            <Header />
            <main>
                <Hero id="about" />
                <Education id="education" />
                <Skills id="skills" />
                <Projects id="projects" />
                <Publications id="publications" />
                <Experience id="experience" />
                <Contact id="contact" />
                <Resume id="resume" />
            </main>
            <Footer />
        </div>
    </Router>
);

export default App;
