import React from 'react';
import styled from 'styled-components';
import { FaInstagram, FaLinkedin, FaGithub, FaEnvelope, FaGraduationCap } from 'react-icons/fa';

const FooterContainer = styled.footer`
    background-color: #2c2c2c; /* Footer Background Color */
    color: #d3d3d3; /* Footer Text Color */
    padding: 1em 0;
    text-align: center;
    margin-top: auto;
`;

const SocialLinks = styled.div`
    display: flex;
    justify-content: center;
    gap: 1em;
    margin-top: 1em;
`;

const SocialLink = styled.a`
    color: #ffffff;
    font-size: 1.5em;
    transition: color 0.3s;
    &:hover {
        color: #f50057; /* Highlight Color */
    }
`;

const Footer = () => (
    <FooterContainer>
        <SocialLinks>
            <SocialLink href="https://www.instagram.com/issac" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/in/issac" target="_blank" rel="noopener noreferrer">
                <FaLinkedin />
            </SocialLink>
            <SocialLink href="https://github.com/issac" target="_blank" rel="noopener noreferrer">
                <FaGithub />
            </SocialLink>
            <SocialLink href="mailto:issac@example.com">
                <FaEnvelope />
            </SocialLink>
            <SocialLink href="https://scholar.google.com/citations?user=Issac" target="_blank" rel="noopener noreferrer">
                <FaGraduationCap />
            </SocialLink>
        </SocialLinks>
        <p>© 2024 Issac Kondreddy. All rights reserved.</p>
    </FooterContainer>
);

export default Footer;
